var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"studentExamResult"}},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbList,"divider":"/"},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-breadcrumbs-item',{attrs:{"to":item.href,"disabled":item.disabled}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}])}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('div',{staticClass:"title"},[_c('h1',[_vm._v(_vm._s(_vm.ExamData.class_name))])]),_c('div',{staticClass:"title"},[_c('h2',{staticClass:"main-color"},[_vm._v(" "+_vm._s(_vm.ExamData.subject_name)+" - "+_vm._s(_vm.ExamData.exam_name)+" ")])])]),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('a',{directives:[{name:"can",rawName:"v-can",value:('view-exam-statistics'),expression:"'view-exam-statistics'"}],staticClass:"main-color",staticStyle:{"text-decoration":"underline"},on:{"click":_vm.goToClassReportPage}},[_vm._v(_vm._s(_vm.$t("View result statistics")))])])],1),_c('v-row',[_c('v-col',{staticClass:"info-list",attrs:{"cols":"12","md":"8","offset-md":"2"}},[_c('ul',{},[_c('li',[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("Date"))+": ")]),_vm._v(_vm._s(_vm.ExamData.exam_date)+" ")]),_c('li',[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("Academic Period"))+":")]),_vm._v(" "+_vm._s(_vm.ExamData.year_name)+" ")]),_c('li',[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("Qualifier"))+":")]),_vm._v(" "+_vm._s(_vm.ExamData.qualifier_name)+" ")])])]),_c('v-col',{staticClass:"icons-div text-right",attrs:{"cols":"12","md":"2"}},[(!_vm.edit && !_vm.ExamData.add && _vm.editExamMarksVisible)?_c('v-icon',{staticClass:"main-color",attrs:{"title":_vm.$t('Edit')},on:{"click":function($event){_vm.edit = true}}},[_vm._v("edit")]):_vm._e()],1),_c('v-col',{staticClass:"hr-div",attrs:{"cols":"12","md":"12"}},[_c('hr')])],1),_c('v-row',[(
        (_vm.ExamData.add && _vm.addMarksVisible) ||
        (!_vm.ExamData.add && _vm.editExamMarksVisible) ||
        _vm.listExamsVisible
      )?_c('v-col',{staticClass:"table-grid",attrs:{"cols":"12","md":"12"}},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-data-table',{staticClass:"elevation-1 level1 ExamMarks",attrs:{"options":_vm.options,"headers":_vm.headers,"items":_vm.ExamMarks,"loading":_vm.loading,"item-key":"id","loading-text":"Loading... Please wait","hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.mark",fn:function({ item }){return [_c('div',{attrs:{"id":"markInput"}},[(!_vm.edit)?_c('span',[_vm._v(_vm._s(item.mark))]):_vm._e(),(_vm.edit)?_c('v-text-field',{attrs:{"rules":[
                  _vm.markRuleCheck(item.mark, item.total_mark),
                  _vm.requiredRules.required,
                  _vm.onlyNumbers.numbers,
                  _vm.greaterThanZer.numbers,
                ],"solo":""},model:{value:(item.mark),callback:function ($$v) {_vm.$set(item, "mark", $$v)},expression:"item.mark"}}):_vm._e()],1)]}},{key:"item.notes",fn:function({ item }){return [(!_vm.edit)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(item.notes)?_c('p',_vm._g({attrs:{"dark":""}},on),[_vm._v(" "+_vm._s(item.notes.slice(0, 20))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.notes))])]):_vm._e(),(_vm.edit)?_c('v-text-field',{attrs:{"solo":""},model:{value:(item.notes),callback:function ($$v) {_vm.$set(item, "notes", $$v)},expression:"item.notes"}}):_vm._e()]}},{key:"item.actions",fn:function({ item }){return [(_vm.ExamData.online_exam)?_c('router-link',{attrs:{"to":'/examCorrection/' +
                _vm.$route.params.academicYear +
                '/' +
                item.class_id +
                '/' +
                item.exam_id +
                '/' +
                item.id,"title":_vm.$t('Student Answers')}},[_c('v-icon',[_vm._v("assignment")])],1):_vm._e()]}}],null,false,146895697)})],1)],1):_vm._e(),(!_vm.listExamsVisible && !_vm.addMarksVisible && !_vm.editExamMarksVisible)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"info_alert"},[_vm._v(" "+_vm._s(_vm.$t( "You do not have sufficient permissions to view this page, please contact your system administrator" ))+" ")])],1):_vm._e()],1),(_vm.edit)?_c('v-row',[_c('v-col',{staticClass:"submit-div text-right",attrs:{"cols":"12","justify":"center","align":"center"}},[(!this.ExamData.add)?_c('v-btn',{staticClass:"ui-btn modal-btn-cancel",attrs:{"large":""},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("Cancel"))+" ")]):_vm._e(),_c('v-btn',{staticClass:"ui-btn submit",attrs:{"type":"submit","disabled":_vm.loading,"loading":_vm.loading,"large":""},on:{"click":_vm.bulkSave}},[_vm._v(" "+_vm._s(_vm.$t("Save"))+" ")])],1)],1):_vm._e(),_c('v-snackbar',{attrs:{"color":_vm.color,"top":true,"right":true},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" "),_c('v-btn',{attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v(" "+_vm._s(_vm.$t("Close"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }