<template>
  <div id="studentExamResult">
    <!-- breadCrumb -->
    <v-breadcrumbs :items="breadcrumbList" divider="/">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <!-- breadCrumb -->
    <v-row>
      <v-col cols="12" sm="10">
        <div class="title">
          <h1>{{ ExamData.class_name }}</h1>
        </div>
        <div class="title">
          <h2 class="main-color">
            {{ ExamData.subject_name }} - {{ ExamData.exam_name }}
          </h2>
        </div>
      </v-col>
      <v-col cols="12" sm="2">
        <a
          v-can="'view-exam-statistics'"
          style="text-decoration: underline"
          class="main-color"
          @click="goToClassReportPage"
          >{{ $t("View result statistics") }}</a
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" offset-md="2" class="info-list">
        <ul class="">
          <li>
            <strong> {{ $t("Date") }}: </strong>{{ ExamData.exam_date }}
          </li>
          <li>
            <strong> {{ $t("Academic Period") }}:</strong>
            {{ ExamData.year_name }}
          </li>
          <li>
            <strong> {{ $t("Qualifier") }}:</strong>
            {{ ExamData.qualifier_name }}
          </li>
        </ul>
      </v-col>
      <v-col cols="12" md="2" class="icons-div text-right">
        <!-- <v-icon
          class="main-color"
          v-if="!edit && ExamData.add && addMarksVisible"
          @click="edit = true"
          >add_circle</v-icon
        > -->
        <v-icon
          class="main-color"
          v-if="!edit && !ExamData.add && editExamMarksVisible"
          @click="edit = true"
          :title="$t('Edit')"
          >edit</v-icon
        >
      </v-col>
      <v-col cols="12" md="12" class="hr-div">
        <hr />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="12"
        class="table-grid"
        v-if="
          (ExamData.add && addMarksVisible) ||
          (!ExamData.add && editExamMarksVisible) ||
          listExamsVisible
        "
      >
        <v-form ref="form" v-model="valid">
          <v-data-table
            :options.sync="options"
            :headers="headers"
            :items="ExamMarks"
            class="elevation-1 level1 ExamMarks"
            :loading="loading"
            item-key="id"
            loading-text="Loading... Please wait"
            hide-default-footer
          >
            <template v-slot:item.mark="{ item }">
              <div id="markInput">
                <span v-if="!edit">{{ item.mark }}</span>
                <v-text-field
                  v-if="edit"
                  v-model="item.mark"
                  :rules="[
                    markRuleCheck(item.mark, item.total_mark),
                    requiredRules.required,
                    onlyNumbers.numbers,
                    greaterThanZer.numbers,
                  ]"
                  solo
                ></v-text-field>
              </div>
            </template>
            <!-- :rules="markRules" -->

            <template v-slot:item.notes="{ item }">
              <v-tooltip bottom max-width="300px" v-if="!edit">
                <template v-slot:activator="{ on }">
                  <p dark v-on="on" v-if="item.notes">
                    {{ item.notes.slice(0, 20) }}
                  </p>
                </template>
                <span>{{ item.notes }}</span>
              </v-tooltip>

              <v-text-field
                v-if="edit"
                v-model="item.notes"
                solo
              ></v-text-field>
            </template>
            <template v-slot:item.actions="{ item }">
              <router-link
                :to="
                  '/examCorrection/' +
                  $route.params.academicYear +
                  '/' +
                  item.class_id +
                  '/' +
                  item.exam_id +
                  '/' +
                  item.id
                "
                v-if="ExamData.online_exam"
                :title="$t('Student Answers')"
              >
                <v-icon>assignment</v-icon>
              </router-link>
            </template>
          </v-data-table>
        </v-form>
      </v-col>
      <!-- (ExamData.add && !addMarksVisible) ||
            (!ExamData.add && !editExamMarksVisible) ||
            (!listExamsVisible && !addMarksVisible && !editExamMarksVisible) -->
      <v-col
        cols="12"
        v-if="!listExamsVisible && !addMarksVisible && !editExamMarksVisible"
      >
        <v-alert class="info_alert">
          {{
            $t(
              "You do not have sufficient permissions to view this page, please contact your system administrator"
            )
          }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="edit">
      <v-col
        cols="12"
        justify="center"
        align="center"
        class="submit-div text-right"
      >
        <v-btn
          class="ui-btn modal-btn-cancel"
          @click="cancel"
          v-if="!this.ExamData.add"
          large
        >
          {{ $t("Cancel") }}
        </v-btn>
        <v-btn
          type="submit"
          class="ui-btn submit"
          @click="bulkSave"
          :disabled="loading"
          :loading="loading"
          large
        >
          {{ $t("Save") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" :color="color" :top="true" :right="true">
      {{ text }}
      <v-btn color="pink" text @click="snackbar = false">
        {{ $t("Close") }}
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import ACL from "../../acl";

export default {
  name: "StudentsExamResults",
  data() {
    return {
      loading: false,
      valid: true,
      snackbar: false,
      color: "",
      text: "",
      className: "",
      ExamData: {},
      ExamMarks: [],
      headers: [
        { text: this.$i18n.t("Student Code"), value: "student_code" },
        { text: this.$i18n.t("Name"), value: `student_name` },
        { text: this.$i18n.t("Marks"), value: "mark", width: "100px" },
        {
          text: this.$i18n.t("Total Marks"),
          value: "total_mark",
          sortable: false,
        },
        { text: this.$i18n.t("Notes"), value: "notes", sortable: false },
        { text: this.$i18n.t("Actions"), value: "actions", sortable: false },
      ],

      options: {
        itemsPerPage: 100,
      },
      academicYear: "",
      classId: "",
      examId: "",
      edit: false,
      requiredRules: {
        required: (v) => !!v || this.$i18n.t("This field is required"),
      },
      // spacesOnly: {
      //   spaces: v =>
      //     /^(.[^ ].)/.test(v) || "This field shouldn't contain spaces only"
      // },

      onlyNumbers: {
        numbers: (v) =>
          (v && /^-?\d*(\.\d+)?$/.test(v)) ||
          this.$i18n.t("This field only accept numbers"),
      },
      greaterThanZer: {
        numbers: (v) =>
          (v && v >= 0) || this.$i18n.t("This field only accept numbers >= 0"),
      },
      addMarksVisible: ACL.checkPermission("add-student-exam-marks"),
      editExamMarksVisible: ACL.checkPermission("edit-student-exam-marks"),
      listExamsVisible: ACL.checkPermission("list-exams"),
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  methods: {
    goToClassReportPage() {
      this.$router.push(
        {
          path:
            "/" +
            "classexamreport/" +
            this.academicYear +
            "/" +
            this.classId +
            "/" +
            this.examId,
        },
        () => {}
      );
    },
    openAddMarks() {
      if (this.ExamData.add && this.addMarksVisible) {
        this.edit = true;
      }
    },
    getExamData() {
      axios
        .get(this.getApiUrl + "/exams/show/" + this.examId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.ExamData = response.data.data;
          if (!this.ExamData.online_exam) {
            this.headers = this.headers.filter(function (row) {
              return row.value != "actions";
            });
          }
          this.openAddMarks();
        });
    },
    getDataFromApi() {
      let sort_type = "";
      let column = "";
      if (this.options.sortBy.length > 0) {
        column = this.options.sortBy[0];
        sort_type = this.options.sortDesc[0] ? "DESC" : "ASC";
      }
      axios
        .get(
          this.getApiUrl +
            "/examsmarks/" +
            this.classId +
            "/" +
            this.examId +
            "?sort_type=" +
            sort_type +
            "&column=" +
            column,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.ExamMarks = response.data.data;
        });
    },

    bulkSave() {
      if (this.valid == true) {
        this.loading = true;
        axios
          .post(
            this.getApiUrl +
              "/examsmarks/storeUpdateBulk/" +
              this.classId +
              "/" +
              this.examId,
            { data: this.ExamMarks },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            }
          )
          .then((response) => {
            this.loading = false;
            if (response.data.status.error == false) {
              this.ExamMarks = response.data.data;
              this.edit = false;
              this.ExamData.add = false;
              this.color = "success";
              this.text = "Exam results saved successfully";
              this.snackbar = true;
            } else {
              this.color = "error";
              this.text = response.data.status.message;
              this.snackbar = true;
            }
          });
      } else {
        this.$refs.form.validate();
      }
    },

    cancel() {
      this.edit = false;
      // call api again to get data from server
      this.getDataFromApi();
    },
    markRuleCheck(mark, total) {
      total = parseFloat(total);
      mark = parseFloat(mark);
      return mark > total ? "Mark shall not exceed the total mark" : true;
    },
    // checkMarkIsNumber(mark) {
    //   mark = parseFloat(mark);
    //   console.log(isNaN(mark));
    //   isNaN(mark) ? "This field only except number" : true;
    // }
  },
  mounted() {
    this.academicYear = this.$router.currentRoute.params.academicYear;
    this.classId = this.$router.currentRoute.params.classId;
    this.examId = this.$router.currentRoute.params.examId;
    this.getExamData();
    this.getDataFromApi();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_forms.scss";
.title h2 {
  margin-bottom: 0 !important;
  font-size: 1.5rem;
}
.info-list {
  padding-top: 0;
  padding-bottom: 0;
  li {
    display: inline-block;
    width: 33%;
    font-size: 13px;
    padding-right: 2px;
  }
}

@media (max-width: 600px) {
  .info-list {
    li {
      font-size: 16px;
      display: block;
      width: 100%;
      margin-bottom: 10px;
    }
  }
}

.icons-div,
.hr-div {
  padding-top: 0;
  padding-bottom: 0;

  hr {
    margin-bottom: 0 !important;
    margin-top: 0.5rem !important;
  }
}

.table-grid {
  padding-top: 0 !important;
}

button.ui-btn.submit:disabled {
  cursor: not-allowed;
}
</style>
